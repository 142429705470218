<template>
    <div>
      <div class="row">
        <div class="col-sm-5 col-8 col-md-5 ">
          <label for="">Unidad</label>
          <select v-model="filters.bimestre" class="form-control">
            <option value="">Elegir una unidad</option>
            <option :value="item.id"
            :key="index" v-for="(item, index) in list_bimester" >
            {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-2">
          <br>
          <a href="#" title="Click para consultar" @click.prevent="list()"
          class="btn btn-outline-info btn-lg">
          <i v-if="sending_request" title="Enviando..." class="fa fa-refresh"></i>
          <i v-if="!sending_request" title="Consultar" class="fa fa-send"></i>
        </a>
        </div>
        <div class="col-sm-2 col-sm-2">
          <br>
          <a title="Click para limpiar filtros"
          href="#" style="font-size: 12px;" @click.prevent="reset_filters()">Limpiar</a>
        </div>
      </div>
      <p style="font-size: 12px;" class="text-disabled">
          Por defecto se listas los datos de la unidad en desarrollo.</p>
      <div class="row">
        <div class="col-md-3" v-for="(item, index) in list_data" :key="index">
          <section class="card">
            <div class="twt-feed purple-darken-bg"
            :style="'background-color: '+ item.course_color+';'">
                <div class="corner-ribon black-ribon">
                    <i class="fa fa-tasks"></i>
                </div>
                <div class="fa fa-tasks wtt-mark"></div>
                <div class="media">
                    <div class="media-body" style="z-index: 15000">
                      <router-link class="text-white"
                      :to="'/tasks/'+item.course+'/'+filters.bimestre">
                        <h3 class="text-white">
                          {{item.course_name}} </h3>
                      </router-link>
                    </div>
                </div>
            </div>
            <div class="weather-category twt-category">
                <ul>
                    <transition name="bounce">
                    <li v-if="!sending_request" title="Total tareas en el presente bimestre">
                      <h5>{{item.total_task}}</h5>
                      Tareas
                    </li>
                    </transition>
                    <transition name="bounce">
                    <li v-if="!sending_request"
                    title="Tareas pendientes de entrega en el presente bimestre">
                      <h5>{{item.pendding_task}}</h5>
                      Pendientes
                    </li>
                    </transition>
                    <transition name="bounce">
                    <li v-if="!sending_request" title="Tareas entregas en el presente bimestre">
                      <h5>{{item.delivery_task}}</h5>
                        Entregadas
                    </li>
                    </transition>
                </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
</template>
<script>

/* eslint-disable */
export default {
  name: 'Courses',
  created(){
    let current_filters = this.get_data_storage('filters_courses')    
    if(current_filters){
      this.filters = current_filters
    }
    if (!this.$store.state.settings_obj){
      setTimeout(()=>{
        if (!this.$store.state.settings_obj.ciclo){
          setTimeout(() => { 
            this.list();
            this.get_list_bimestres(); 
          }, 200)
        }
        if (this.$store.state.settings_obj.ciclo){
          this.list();
          this.get_list_bimestres(); 
        }
      }, 300)
      return false;
    }
    if (this.$store.state.settings_obj){
      this.list();
      this.get_list_bimestres();
    }
  },
  data() {
    return {
      list_data: [],
      filters: {
        bimestre: '',
      },
      list_bimester: [],
      url: 'courses/',
      url_bimestre: 'bimestres/',
      sending_request: false,
    };
  },
  methods: {
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {      
      return JSON.parse(localStorage.getItem(name));      
    },
    reset_filters: function () {            
      this.filters = {
        bimestre: '',
      }
      this.remove_storage('filters_courses')
      this.list()
    },
    list() {
      const payload = {};
      payload.ciclo = this.$store.state.settings_obj.ciclo
      if (this.filters.bimestre) {
        payload.bimester = this.filters.bimestre
      }
      payload.list_courses = "True"
      const self = this;
      if (this.sending_request) return false
      this.sending_request = true
      this.sendRequest(payload).then((data) => {
        self.list_data = data.data;            
        this.sending_request = false
        this.save_storage('filters_courses', this.filters,)
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)        
      });
      return null;
    },
    get_list_bimestres() {
      const payload = {};
      payload.ciclo = this.$store.state.settings_obj.ciclo
      const self = this;
      this.sendRequest_with_url(this.url_bimestre, payload).then((data) => {      
        self.list_bimester = data.data;
      }).catch((error) => {
        this.handlerError(error)
      });
      return null;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url,{params:  payload});
      // const result = await this.$http.get(this.url, payload);
      return result;
    },
    async sendRequest_with_url(url, payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
};

</script>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;            
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>